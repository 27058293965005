import React, {useEffect} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {classnames} from '@bem-react/classnames';
import {IClassNameProps} from '@bem-react/core';
import {useDispatch} from 'react-redux';

import {SET_MUSIC_TOPS} from '../../actions/playerAction';
import {PRESS_ALIAS} from '../../constants/constants';
import {checkCategoryProps} from '../../helpers/checkCategoryProps';
import {ICategory, IContentInfo, IMusicTop} from '../../types/types';
import {CategoryItem} from '../CategoryItem/CategoryItem';
import {MusicItem} from '../MusicItem/MusicItem';

import './CategoryList.css';

export interface ICategoryListPageProps extends IClassNameProps {
    categoriesData: ICategory[];
    handlePlay(): void;
    handlePause(): void;
}

export const CategoryList = (props: ICategoryListPageProps) => {

    const { handlePlay, handlePause } = props;

    const getCategoriesItems = (categories: ICategory[], musicCategories: ICategory[]) => {
        const newCategoriesList: ICategory[] = [];
        // категория "пресса" д.б. первой
        categories.map((category: ICategory) => {
            if (category.alias === PRESS_ALIAS) {
                newCategoriesList.unshift(category);
            } else {
                newCategoriesList.push(category);
            }
        });

        return (
            <>
                {
                    newCategoriesList.slice(0, 2).map((category: ICategory) => {
                        if (checkCategoryProps(category)) {
                            return (
                                <CategoryItem name={category.name} key={category.alias} contentData={category.contents}
                                              id={category.id}
                                              alias={category.alias} page={'main'}/>
                            )
                        }
                    })
                }
                {
                    <MusicItem
                        handlePlay={handlePlay}
                        handlePause={handlePause}
                    />
                }
                {
                    newCategoriesList.slice(2).map((category: ICategory) => {
                        if (checkCategoryProps(category)) {
                            return (
                                <CategoryItem name={category.name} key={category.alias} contentData={category.contents}
                                              id={category.id}
                                              alias={category.alias} page={'main'}/>
                            )
                        }
                    })
                }
            </>
        )
    };

    const dispatch = useDispatch();

    const categoryList: ClassNameFormatter = cn('CategoryList');
    const {categoriesData} = props;
    const categoriesMusicData: ICategory[] = categoriesData.filter((category: ICategory) => category.contents
            .filter((content: IContentInfo) => content.meta_params
                && content.meta_params.content_type_id === 5544).length)
        .map((category: ICategory) => {
            const musicContent: IContentInfo[] = category.contents.filter((content: IContentInfo) => content.meta_params
                && content.meta_params.content_type_id === 5544);
            return {
                ...category,
                contents: musicContent,
            }
        });

    const categoriesWithoutMusic = categoriesData.map((category: ICategory) => {
        if (category.contents
            .filter((content: IContentInfo) => content.meta_params
                && content.meta_params.content_type_id === 5544).length) {
            const contentWithoutMusic: IContentInfo[] = category.contents
                .filter((content: IContentInfo) => !content.meta_params
                    || content.meta_params.content_type_id !== 5544);
            return {
                ...category,
                contents: contentWithoutMusic,
            }
        }
        return category;
    });

    useEffect(() => {
        if (categoriesMusicData && categoriesMusicData.length) {
            const musicTops: IMusicTop[] = categoriesMusicData.map((category: ICategory) => {
                return { alias: category.alias };
            });
            if (musicTops.length) {
                dispatch({
                    type: SET_MUSIC_TOPS,
                    payload: musicTops,
                })
            }
        }
    }, [categoriesMusicData]);

    const categoriesList = getCategoriesItems(categoriesWithoutMusic, categoriesMusicData);

    return (
        <div className={classnames(categoryList(), props.className)}>
            {categoriesList}
        </div>
    );
}
